import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, call} from 'redux-saga/effects'
import { RefreshTokenModel, TenantFeatureList, UserModel } from '../models/UserModel'
import {getUserByToken, refreshToken} from "./AuthCRUD";

export interface ActionWithPayload<T> extends Action {
  payload?: T & { callback?: () => void };
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',

  RemoveToken: '[Timeout Provider] Action',
  RefreshTokenRequested: '[Request Refresh Token] Action',
  RefreshTokenLoaded: '[Load Refresh Token Data] Auth API',

  TenantFeaturesLoaded: '[Load Feature Flags] Feature Flags API',
  SetTenantFeatures: '[Set Feature Flags] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  expiredToken: undefined
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  expiredToken?: string
  tenantFeatureList?: TenantFeatureList
}

export const reducer = persistReducer(
  {storage, key: 'isg-next-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.RemoveToken: {
        return {...state, accessToken: undefined, expiredToken: state.accessToken }
      }

      case actionTypes.RefreshTokenLoaded: {
        const accessToken = action.payload?.accessToken;
        const user = action.payload?.user;
        return {...state, user: user, accessToken: accessToken}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetTenantFeatures: {
        const tenantFeatureList = action.payload?.tenantFeatureList
        return { ...state, tenantFeatureList }
      }

      case actionTypes.TenantFeaturesLoaded: {
        const tenantFeatureList = action.payload?.tenantFeatureList
        return { ...state, tenantFeatureList }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),

  setTenantFeatures: (tenantFeatureList: TenantFeatureList) => ({ type: actionTypes.SetTenantFeatures, payload: { tenantFeatureList } }),

  store: () => ({ type: "def" }),

  removeToken: () => ({ type: actionTypes.RemoveToken }),

  refreshTokenRequested: (callback?: () => void) => ({
    type: actionTypes.RefreshTokenRequested,
    payload: { callback },
  }),

  fulfillRefreshToken: (data: RefreshTokenModel) => ({
    type: actionTypes.RefreshTokenLoaded,
    payload: data
  })
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.accessToken;
    // @ts-ignore
    let token = yield select(getToken)
    const { data: user } = yield getUserByToken(token)
    yield put(actions.fulfillUser(user))
  })

  yield takeLatest(actionTypes.RefreshTokenRequested, function* refreshTokenRequestedSaga(action: ActionWithPayload<{}>) {
    // @ts-ignore
    const getToken = (state) => state.auth.expiredToken;
    // @ts-ignore
    let token = yield select(getToken)
    const { data } = yield call(refreshToken, token);
    yield put(actions.fulfillRefreshToken(data));

    if (action.payload?.callback) {
      action.payload.callback();
    }

  });
}
