import axios from 'axios'
import { CreateScaleFormData, EmailSettings, ProductCategoryFilter, ScaleMappingData, ScaleMappingFilter, SessionTimeout, Tolerance, UpdateScaleMappingPayload } from '../models/settings-model';
import { SortDescriptor } from '@progress/kendo-data-query';

export const SETTINGS_URL = `${process.env.REACT_APP_API_URL}/Settings`

export const getEmailSettings = async () => {
  return await axios.get(`${SETTINGS_URL}/email-settings`);
}

export const saveEmailSettings = async (emailSettings: EmailSettings) => {
  return await axios.post(`${SETTINGS_URL}/email-settings`, emailSettings);
}

export const getToleranceSettings = async () => { 
  return await axios.get(`${SETTINGS_URL}/tolerance-setting`);
}

export const getSessionTimeoutSettings = async () => {
  return await axios.get(`${SETTINGS_URL}/session-timeout-setting`);
}

export const saveToleranceSettings = async (toleranceSettings: Tolerance) => {
  return await axios.put(`${SETTINGS_URL}/tolerance-setting`, toleranceSettings);
}

export const saveSessionTimeoutSettings = async (sessionTimeout: SessionTimeout) => {
  return await axios.put(`${SETTINGS_URL}/session-timeout-setting`, sessionTimeout);
}

export const getProductCategorySettings = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter: ProductCategoryFilter
) => {
  return await axios.post(`${SETTINGS_URL}/productcategory-setting`, {
    skip,
    take,
    sort: (pageSort && pageSort.field && pageSort.dir) ? `${pageSort.field} ${pageSort.dir}` : '',
    filter
  });
}

export const updateProductCategoryFriendlyName = async (scrapITClass: string, scrapITCategory: string, friendlyName: string) => {
  return await axios.post(`${SETTINGS_URL}/productcategory-setting/update-friendly-name`, {
    scrapITClass,
    scrapITCategory,
    friendlyName
  });
};

export const getScaleMappingData = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter: ScaleMappingFilter
) => {
  return await axios.post(`${SETTINGS_URL}/scale-mapping/table`, {
    skip,
    take,
    filter,
    sort: (pageSort && pageSort.field && pageSort.dir) ? `${pageSort.field} ${pageSort.dir}` : '',
  });
}

export const createScaleMapping = async (payload: CreateScaleFormData) => {
  return await axios.post(`${SETTINGS_URL}/scale-mapping`, payload);
};

export const deleteScaleMapping = async (id: number) => {
  return await axios.delete(`${SETTINGS_URL}/scale-mapping?id=${id}`)
}

export const updateScaleMapping = async (payload: UpdateScaleMappingPayload) => {
  return await axios.patch(`${SETTINGS_URL}/scale-mapping`, payload);
}
